import cn from 'classnames'
import Image from 'next/image'

import { AvatarProps } from './interfaces/Avatar'

export function Avatar({ src, className, alt }: AvatarProps) {
  return (
    <div
      className={cn(
        'rounded-full overflow-hidden bg-gray dark:bg-dark-9',
        className
      )}
    >
      <div className='relative pt-[100%] w-full h-full'>
        {src ? (
          <Image
            src={src}
            alt={alt}
            layout='fill'
            quality={25}
            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
          />
        ) : (
          <Image
            src='/images/avatar.png'
            layout='fill'
            alt='avatar image src'
            quality={25}
            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
          />
        )}
      </div>
    </div>
  )
}
