import cn from 'classnames'

import { ShareLinkIcon } from '@components/icons/ShareLinkIcon'
import { LinkShareButtonProps } from './interface'

const colors = {
  white: {
    className: 'border border-gray-4',
    iconClassName: 'text-secondary-1',
  },
  gray: {
    className: 'bg-secondary-1',
    iconClassName: 'text-white',
  },
}

export function LinkShareButton({
  url,
  children,
  handleCopyLink,
  className,
  color = 'white',
  iconHeight = '24',
  iconWidth = '24',
}: LinkShareButtonProps) {
  return (
    <button
      type='button'
      className={cn(
        'flex rounded-full items-center justify-center w-10 h-10 min-w-10 min-h-10 dark:border-dark-10 ',
        className,
        colors[color].className
      )}
      onClick={() => handleCopyLink(url)}
    >
      {children || (
        <ShareLinkIcon
          className={colors[color].iconClassName}
          width={iconWidth}
          height={iconHeight}
        />
      )}
    </button>
  )
}
